import { AppStage } from 'src/app/presentation/view-models';
import { IConfig } from './config.type';
import { buildInfo } from './build-info';

export const config: IConfig = {
	IDP_URL: 'https://sandbox-login.bannerflow.com',
	SCS_URL: 'https://sandbox-scs.bannerflow.com',
	SAS_URL: 'https://sandbox-sas.bannerflow.com',
	SPS_URL: 'https://sandbox-sps.bannerflow.com',
	B2_URL: 'https://sandbox-app.bannerflow.com',
	HOME_URL: 'https://sandbox-home.bannerflow.com',
	BM_URL: 'https://sandbox-api.bannerflow.com/brand-manager',
	ACA_URL: 'https://sandbox-api.bannerflow.com/account-access',
	CLV_URL: 'https://sandbox-api.bannerflow.com/list-service',
	STUDIO_URL: 'https://sandbox-studio.bannerflow.com',
	SAPI_URL: 'https://sandbox-sapi.bannerflow.com',
	ACG_URL: 'https://sandbox-api.bannerflow.com/preview',
	CLIENT_ROOT: 'https://sandbox-scm.bannerflow.com',
	NOTS_URL: 'https://sandbox-api.bannerflow.com/notification-service/api',
	STAGE: AppStage.sandbox,
	RELEASE_NAME: 'noop',
	buildInfo: buildInfo,
	logLevel: 'debug',
	CLIENT_ID: '4JzAcKmdDvIFlHVBRwpQLuJFo0Tn3A6R',
	NSSignalR: {
		enabled: true,
		url: 'https://api.bannerflow.local/notification-service/hub',
	},
	featureFlags: {
		enabled: true,
		url: 'https://bf-feature-flags.azurewebsites.net/api/frontend',
		clientKey:
			'*:development.b00ab45f8b6f2247492d91e8de452bca1bfcd49b37dfdb3be6c69e1c',
	},
};
