import { Injectable } from '@angular/core';
import {
	IAd,
	IAdContentDictionary,
	IAdStatus,
	IAssignedCreative,
} from 'src/app/core/models';
import { AdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import { CreativesAdMapperService } from './creatives-ad.mapper';
import { AdValidationMapperService } from './ad-validation.mapper';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import {
	AdFormatEnum,
	IAdPlacementBaseVM,
	IAdPlacementChildVM,
	IAdVM,
	IDetailedAdVM,
	IAdWithPlacementsVM,
	MediaTypeEnum,
	IAdContentTemplateVM,
	TemplateTypeEnum,
	IAssignedCreativeVM,
	IDetailedBulkAdVM,
} from 'src/app/presentation/view-models';
import { IMixedContentVM } from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';
import { CollectionElementVM } from 'src/app/presentation/view-models/ad/ad-content-template/collection-element.vm';

@Injectable({
	providedIn: 'root',
})
export class AdMappersService {
	constructor(
		public creativesMapper: CreativesAdMapperService,
		public validationMapper: AdValidationMapperService,
	) {}

	public toAdVM(ad: IAd): IAdVM {
		const adViewModel: IAdVM = {} as IAdVM;

		if (ad.default.content) {
			adViewModel.url = undefined;
			adViewModel.primaryText =
				ad.default.content[AdContentIdEnum.primaryText];
			adViewModel.hasContent = Object.keys(ad.default.content).length > 0;
		}

		adViewModel.id = ad.id;
		adViewModel.adFormatId = ad.adFormatId;
		adViewModel.adGroupId = ad.adGroupId;
		adViewModel.languageId = ad.languageId;
		adViewModel.name = ad.name;

		adViewModel.children = this.fromAdToChildrenVM(ad);

		return adViewModel;
	}

	public toAdWithPlacementsVM(ad: IAd): IAdWithPlacementsVM {
		const adVM = this.toAdVM(ad);

		return {
			...adVM,
			placements: ad.placements?.map((placement) =>
				this.mapAdPlacement(placement),
			),
		};
	}

	public toDetailedAdVM(
		ad: IAd,
		contentTemplates: IAdContentTemplateVM[],
	): IDetailedAdVM {
		const detailedAdVM: IDetailedAdVM = {
			id: ad.id,
			name: ad.name,
			adGroupId: ad.adGroupId,
			adFormatId: ad.adFormatId,
			languageId: ad.languageId,
			mediaType: this.mapAdMediaType(
				ad.default.creatives?.[0]?.renderingOption,
				ad.adFormatId,
			),
		} as IDetailedAdVM;

		if (ad.default.creatives) {
			detailedAdVM.adCreatives = ad.default.creatives.map((creative) =>
				this.creativesMapper.toCreativeVM(creative),
			);
		}

		if (ad.default.content) {
			detailedAdVM.defaultContent = this.toContentVM(
				ad.default.content,
				contentTemplates,
			);
		}

		detailedAdVM.carouselCards = null;

		detailedAdVM.placements = ad.placements?.map((placement) =>
			this.mapAdPlacement(placement),
		);

		return detailedAdVM;
	}

	public fromDetailedAdVM(
		detailedAd: IDetailedAdVM,
		contentTemplates: IAdContentTemplateVM[],
	): IAd {
		const adModel: IAd = {
			id: detailedAd.id,
			name: detailedAd.name,
			adGroupId: detailedAd.adGroupId,
			adFormatId: detailedAd.adFormatId,
			languageId: detailedAd.languageId,
			default: {
				content: this.toContent(
					detailedAd.defaultContent,
					contentTemplates,
				),
				creatives: this.toCreatives(
					detailedAd.adCreatives,
					detailedAd.mediaType,
				),
				cards: undefined,
			},
		};

		return adModel;
	}

	public fromDetailedBulkAdVM(
		adVM: IDetailedBulkAdVM,
		savedAdVM: IDetailedAdVM,
		contentTemplates: IAdContentTemplateVM[],
	): IAd {
		const { id, name, adFormatId, languageId, adGroupId } = savedAdVM;

		const isMixedCreativeUntouched =
			adVM.mixedDefaultContent?.creatives && !adVM.adCreatives?.length;

		const creatives = isMixedCreativeUntouched
			? this.toCreatives(savedAdVM.adCreatives, savedAdVM.mediaType)
			: this.toCreatives(adVM.adCreatives, adVM.mediaType);

		const content: IAdContentDictionary =
			this.fromCombinedContentVMToContent(
				adVM,
				savedAdVM,
				contentTemplates,
			);

		const adModel: IAd = {
			id,
			name,
			adGroupId,
			adFormatId,
			languageId,
			default: {
				content,
				creatives,
				cards: undefined,
			},
		};

		return adModel;
	}

	private fromCombinedContentVMToContent(
		adVM: IDetailedBulkAdVM,
		savedAdVM: IDetailedAdVM,
		contentTemplates: IAdContentTemplateVM[],
	): IAdContentDictionary {
		const mappedContent: IAdContentDictionary = {};
		const mixedContent = adVM.mixedDefaultContent?.content || {};

		contentTemplates.forEach((template) => {
			const isGroup = template.type === TemplateTypeEnum.group;
			const isMutuallyExclusiveGroup =
				this.isMutuallyExclusiveGroup(template);

			if (isMutuallyExclusiveGroup) {
				this.mapMutuallyExclusiveGroupToBulkContent({
					template,
					adVM,
					savedAdVM,
					mixedContent,
					mappedContent,
				});
			} else if (isGroup) {
				template.subTemplates?.forEach((subTemplate) => {
					this.mapSimpleTemplateToBulkContent({
						template: subTemplate.template,
						adVM,
						savedAdVM,
						mixedContent,
						mappedContent,
					});
				});
			} else {
				this.mapSimpleTemplateToBulkContent({
					template,
					adVM,
					savedAdVM,
					mixedContent,
					mappedContent,
				});
			}
		});

		return mappedContent;
	}

	private mapMutuallyExclusiveGroupToBulkContent({
		template,
		adVM,
		savedAdVM,
		mixedContent,
		mappedContent,
	}: {
		template: IAdContentTemplateVM;
		adVM: IDetailedBulkAdVM;
		savedAdVM: IDetailedAdVM;
		mixedContent: IMixedContentVM;
		mappedContent: IAdContentDictionary;
	}): void {
		const selectedSubTemplateId =
			adVM.defaultContent[template.id] ??
			this.getSelectedSubTemplateId(adVM.defaultContent, template, false);
		const selectedSavedSubTemplateId =
			savedAdVM.defaultContent[template.id] ??
			this.getSelectedSubTemplateId(
				savedAdVM.defaultContent,
				template,
				false,
			);

		if (selectedSubTemplateId) {
			this.mapSimpleTemplateToBulkContent({
				template: template.subTemplates.find(
					(subTemplate) =>
						subTemplate.template.id === selectedSubTemplateId,
				).template,
				adVM,
				savedAdVM,
				mixedContent,
				mappedContent,
			});
		} else if (selectedSavedSubTemplateId) {
			mappedContent[selectedSavedSubTemplateId] =
				savedAdVM.defaultContent[selectedSavedSubTemplateId];
		}
	}

	private mapSimpleTemplateToBulkContent({
		template,
		adVM,
		savedAdVM,
		mixedContent,
		mappedContent,
	}: {
		template: IAdContentTemplateVM;
		adVM: IDetailedBulkAdVM;
		savedAdVM: IDetailedAdVM;
		mixedContent: IMixedContentVM;
		mappedContent: IAdContentDictionary;
	}): void {
		const value = this.mapBulkValue(
			template,
			mixedContent,
			adVM.defaultContent,
			savedAdVM.defaultContent,
		);

		if (value !== undefined) {
			mappedContent[template.id] = value;
		}
	}

	private mapBulkValue(
		template: IAdContentTemplateVM,
		mixedContent: IMixedContentVM,
		content: IAdContentDictionary,
		savedContent: IAdContentDictionary,
	): any {
		const isMixed = Boolean(mixedContent[template.id]);
		const isTextCollection =
			template.type === TemplateTypeEnum.textCollection;
		const isEmptyValue = this.isEmptyValue(content, template.id);

		const savedValue = savedContent[template.id];
		const newValue = content[template.id];

		if (isMixed) {
			const source = isEmptyValue ? savedValue : newValue;
			return isTextCollection
				? this.fromCollectionElementsVM(source)
				: source;
		}

		if (!isEmptyValue) {
			return isTextCollection
				? this.fromCollectionElementsVM(newValue)
				: newValue;
		}

		return isTextCollection
			? this.fromCollectionElementsVM(newValue)
			: undefined;
	}

	private fromCollectionElementsVM(
		collection: CollectionElementVM[],
	): string[] {
		return collection?.map(({ value }) => value) || [''];
	}

	private toContentVM(
		originalContent: IAdContentDictionary,
		contentTemplates: IAdContentTemplateVM[],
	): IAdContentDictionary {
		const mappedContent: IAdContentDictionary = {};

		contentTemplates.forEach((template) => {
			const isMutuallyExclusiveGroup =
				this.isMutuallyExclusiveGroup(template);

			if (isMutuallyExclusiveGroup) {
				const selectedSubTemplateId = this.getSelectedSubTemplateId(
					originalContent,
					template,
				);

				if (selectedSubTemplateId) {
					mappedContent[template.id] = selectedSubTemplateId;
				}
			} else if (template.type === TemplateTypeEnum.textCollection) {
				mappedContent[template.id] = this.mapCollectionValues(
					originalContent[template.id],
				);
			} else {
				this.simpleValueToContentVM(
					template,
					originalContent,
					mappedContent,
				);
			}

			template.subTemplates?.forEach(({ template: subTemplate }) => {
				if (subTemplate.type === TemplateTypeEnum.textCollection) {
					mappedContent[subTemplate.id] = this.mapCollectionValues(
						originalContent[subTemplate.id],
					);
				} else {
					this.simpleValueToContentVM(
						subTemplate,
						originalContent,
						mappedContent,
					);
				}
			});
		});

		return mappedContent;
	}

	private simpleValueToContentVM(
		template: IAdContentTemplateVM,
		originalContent: IAdContentDictionary,
		mappedContent: IAdContentDictionary,
	): any {
		if (!this.isEmptyValue(originalContent, template.id)) {
			mappedContent[template.id] = originalContent[template.id];
		} else if (template.metadata.defaultValue) {
			mappedContent[template.id] = template.metadata.defaultValue;
		}
	}

	private toContent(
		content: IAdContentDictionary,
		contentTemplates: IAdContentTemplateVM[],
	): IAdContentDictionary {
		const mappedContent: IAdContentDictionary = { ...content };

		contentTemplates.forEach((template) => {
			const isMutuallyExclusiveGroup =
				this.isMutuallyExclusiveGroup(template);

			if (isMutuallyExclusiveGroup) {
				const subTemplatesIds = template.subTemplates.map(
					(subTemplate) => subTemplate.template.id,
				);

				subTemplatesIds.forEach((id) => {
					if (id !== content[template.id]) {
						delete mappedContent[id];
					}
				});

				delete mappedContent[template.id];
			} else if (
				template.type === TemplateTypeEnum.textCollection &&
				mappedContent[template.id]
			) {
				mappedContent[template.id] = content[template.id].map(
					({ value }) => value,
				);
			}
		});

		return mappedContent;
	}

	private isMutuallyExclusiveGroup(template: IAdContentTemplateVM): boolean {
		return (
			template.type === TemplateTypeEnum.group &&
			template.metadata.isMutuallyExclusive
		);
	}

	/**
	 * Finds the id of saved subTemplate to be assigned as a value of mutually exclusive group template
	 * @param allowFallback if true, returns the first subTemplate's id
	 * @returns ad to adVM: saved value or first subTemplate's id if there is no value; adVM to ad: saved value without any fallback
	 */
	private getSelectedSubTemplateId(
		content: IAdContentDictionary,
		template: IAdContentTemplateVM,
		allowFallback: boolean = true,
	): string | undefined {
		const subTemplatesIds = template.subTemplates.map(
			(subTemplate) => subTemplate.template.id,
		);
		const idsWithValues = subTemplatesIds.filter((id) =>
			this.hasContentValue(content, id),
		);

		if (idsWithValues.length === 1) {
			return idsWithValues[0];
		} else if (idsWithValues.length === 0 && allowFallback) {
			return subTemplatesIds[0];
		}
	}

	private hasContentValue(
		content: IAdContentDictionary,
		contentId: string,
	): boolean {
		return (
			content.hasOwnProperty(contentId) &&
			content[contentId] !== undefined
		);
	}

	private isEmptyValue(
		content: IAdContentDictionary,
		contentId: string,
	): boolean {
		const value = content[contentId];

		if (!value) {
			return true;
		}

		if (Array.isArray(value) && value.length < 2) {
			if (value.length === 0) {
				return true;
			}

			const singleItem = value[0];
			const isEmptyObject =
				typeof singleItem === 'object' &&
				(!singleItem || singleItem.value === '');

			return singleItem === '' || isEmptyObject;
		}

		return false;
	}

	private fromAdToChildrenVM(ad: IAd): IAdPlacementChildVM[] {
		const children: IAdPlacementChildVM[] = [];

		if (ad.placements) {
			ad.placements.forEach((placement) => {
				children.push(this.toAdChildVM(ad, placement));
			});
		}

		return children;
	}

	private toAdChildVM(ad: IAd, placement: IAdPlacement): IAdPlacementChildVM {
		return {
			adId: ad.id,
			placementId: placement.placementId,
			isCustomized: placement?.isCustomized,
			languageId: ad.languageId,
			mediaType: this.mapMediaType(
				placement?.creative?.renderingOption,
				ad.adFormatId,
			),
			adFormatId: ad.adFormatId,
			adCreatives: undefined,
			primaryText: undefined,
			primaryTexts: undefined,
			url: undefined,
			websiteUrls: undefined,
		};
	}

	private mapAdMediaType(
		renderingOptionTypeId: string,
		adFormatId: AdFormatEnum,
	): MediaTypeEnum | undefined {
		switch (adFormatId) {
			case AdFormatEnum.carousel:
				return undefined;
			case AdFormatEnum.singleImage:
				return MediaTypeEnum.Image;
			case AdFormatEnum.singleVideo:
				return MediaTypeEnum.Video;
			default:
				if (!renderingOptionTypeId) {
					return undefined;
				}

				switch (renderingOptionTypeId) {
					case MediaTypeEnum.Image:
						return MediaTypeEnum.Image;
					case MediaTypeEnum.Video:
						return MediaTypeEnum.Video;
					default:
						return MediaTypeEnum.Image;
				}
		}
	}

	private mapMediaType(
		renderingOptionTypeId: string,
		adFormatId: AdFormatEnum,
	): MediaTypeEnum {
		if (adFormatId === AdFormatEnum.carousel) {
			return MediaTypeEnum.Carousel;
		} else {
			switch (renderingOptionTypeId) {
				case MediaTypeEnum.Image:
					return MediaTypeEnum.Image;
				case MediaTypeEnum.Video:
					return MediaTypeEnum.Video;
				default:
					return MediaTypeEnum.Image;
			}
		}
	}

	private mapAdPlacement(adPlacement: IAdPlacement): IAdPlacementBaseVM {
		return {
			placementId: adPlacement.placementId,
			creative: adPlacement.creative,
			content: adPlacement.content,
			isCustomized: adPlacement.isCustomized,
			cards: adPlacement.cards
				? adPlacement.cards.map((card) => ({ creative: card.creative }))
				: null,
		};
	}

	public toAdsStatusList(adIds: string[]): IAdStatus[] {
		const adStatusList: IAdStatus[] = [];

		adIds.forEach((adId) => {
			adStatusList.push(this.toAdStatusItem(adId));
		});

		return adStatusList;
	}

	private toAdStatusItem(adId: string): IAdStatus {
		return {
			id: adId,
			status: 'Not published',
			network: null,
			canBeUpdated: false,
			isExternalStatus: false,
		};
	}

	private toCreatives(
		adCreatives: IAssignedCreativeVM[],
		mediaType: MediaTypeEnum = MediaTypeEnum.Image,
	): IAssignedCreative[] {
		return adCreatives
			? adCreatives.map((creative) =>
					this.creativesMapper.toCreative(creative, mediaType),
				)
			: [];
	}

	private mapCollectionValues(contentValue: string[]): CollectionElementVM[] {
		const collectionElements: CollectionElementVM[] = [];

		if (contentValue?.length) {
			contentValue.forEach((value, index) => {
				if (index === 0) {
					collectionElements.push({ value, isActive: true });
				} else {
					collectionElements.push({ value, isActive: false });
				}
			});
		} else {
			collectionElements.push({
				value: '',
				isActive: true,
			});
		}

		return collectionElements;
	}
}
