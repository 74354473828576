import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommonFeatureActions } from 'src/app/features/common/states/actions';

export enum NotFoundReason {
	campaign = 'campaign',
	adgroup = 'adgroup',
	ad = 'ad',
	default = 'default',
}

@Injectable()
export class NotFoundRequestInterceptor implements HttpInterceptor {
	public error422: HttpErrorResponse | null = null;
	constructor(
		private readonly authHttpInterceptor: AuthHttpInterceptor,
		private readonly router: Router,
		private readonly store: Store,
	) {}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (window['Cypress'] || (window as any).env?.isTest) {
			return next.handle(request);
		}

		return this.authHttpInterceptor.intercept(request, next).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 422) {
					if (error.error.errors) {
						this.error422 = error.error.errors;
					}
					this.store.dispatch(
						CommonFeatureActions.appErrorOccurred({ error }),
					);
					return throwError(() => error);
				}

				if (error.status === 404) {
					const reason = this.notFound(request.url, request.method);

					if (reason) {
						return timer(50).pipe(
							switchMap(() => {
								if (this.error422) {
									this.router.navigate(['/not-found'], {
										queryParams: {
											reason,
											errorResponse: JSON.stringify(
												this.error422,
											),
										},
									});
									this.error422 = null;
								} else {
									this.router.navigate(['/not-found'], {
										queryParams: { reason },
									});
								}
								return throwError(() => error);
							}),
						);
					}
				}

				return throwError(() => error);
			}),
		);
	}

	private notFound(url: string, method: string): NotFoundReason {
		const campaignRegex =
			/^https:\/\/(?:[a-zA-Z0-9-]+(?:-sandbox)?\.[a-zA-Z0-9.-]+|\[?localhost]?):?(?:\d{1,5})?\/api\/social-campaigns/;
		const adgroupRegex =
			/^https:\/\/(?:[a-zA-Z0-9-]+(?:-sandbox)?\.[a-zA-Z0-9.-]+|\[?localhost]?):?(?:\d{1,5})?\/api\/ad-groups\/[a-fA-F0-9-]+$/;
		const adgroupRegexSecond =
			/^https:\/\/(?:[a-z.-]+)(?:\/api\/ads\/[a-f0-9-]+)\?adGroupId=[a-z0-9-]+$/;
		const adRegex =
			/^https:\/\/(?:[a-zA-Z0-9-]+(?:-sandbox)?\.[a-zA-Z0-9.-]+|\[?localhost]?):?(?:\d{1,5})?\/api\/ads/;

		if (campaignRegex.test(url) && method.toUpperCase() === 'GET') {
			return NotFoundReason.campaign;
		} else if (
			(adgroupRegex.test(url) && method.toUpperCase() === 'GET') ||
			(adgroupRegexSecond.test(url) && method.toUpperCase() === 'GET')
		) {
			return NotFoundReason.adgroup;
		} else if (adRegex.test(url) && method.toUpperCase() === 'GET') {
			return NotFoundReason.ad;
		} else {
			return NotFoundReason.default;
		}
	}
}
