import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { ConfigService } from 'src/app/core/config/config.service';
import { IAccountBrandSlug } from './account-brand-slug.model';

@Component({
	selector: 'redirect',
	imports: [UIModule],
	templateUrl: 'home-redirect.component.html',
})
export class HomeRedirectComponent implements OnInit {
	private readonly route = inject(ActivatedRoute);
	private readonly destroyRef = inject(DestroyRef);
	private readonly httpClient = inject(HttpClient);

	public ngOnInit(): void {
		const accountSlug = this.route.snapshot.paramMap.get('accountSlug');
		const brandSlug = this.route.snapshot.paramMap.get('brandSlug');

		if (!(accountSlug && brandSlug)) {
			this.fetchDefaultSlugsAndRedirect();
		}
	}

	private fetchDefaultSlugsAndRedirect(): void {
		this.httpClient
			.get<IAccountBrandSlug>(
				`${ConfigService.config.ACA_URL}/api/user/defaults`,
			)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (accountAndBrandSlug: IAccountBrandSlug) => {
					window.location.href = `${ConfigService.config.HOME_URL}/${accountAndBrandSlug.accountSlug}/${accountAndBrandSlug.brandSlug}/campaigns`;
				},
				error: (error) => {
					console.error(
						'Error fetching default account and brand slug:',
						error,
					);
				},
			});
	}
}
