import { Injectable } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { CommonFeatureActions } from '../actions';
import { Router } from '@angular/router';

@Injectable()
export class SocialEffects {
	constructor(
		private readonly router: Router,
		private actions$: Actions,
		private notificationService: UINotificationService,
		private textPipe: TextPipe,
	) {}

	public showNotificationOnError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommonFeatureActions.appErrorOccurred),
			map(({ error }) => {
				let errorMessage = this.textPipe.transform(
					'Shared.SOMETHING_WENT_WRONG',
				);
				const apiError = error.error;

				if (apiError?.errors) {
					errorMessage = Object.values(apiError.errors)
						.flat()
						.join('\n');
				} else if (apiError.title) {
					errorMessage = apiError.title;
				}

				setTimeout(() => {
					if (!this.router.url.includes('/not-found')) {
						this.notificationService.open(errorMessage, {
							type: 'error',
							placement: 'top',
							width: 31.6,
							autoCloseDelay: 10000,
							icon: 'warning',
						});
					}
				}, 100);

				return CommonFeatureActions.notificationShown();
			}),
		),
	);
}
