<div class="content" *ngIf="!isLoading; else loading">
    <div class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title">
        <h1>{{ "NotFoundPageComponent.WE_ARE_SORRY" | text }}</h1>
    </div>
    <div class="text">
        <p>{{ message }}</p>
        <p *ngIf="errorStatus" class="error-status">{{ errorStatus }}</p>
        <p class="second-ph">
            {{ "NotFoundPageComponent.IF_YOU_NEED_ANY_ASSISTANCE" | text }}<br />{{
            "NotFoundPageComponent.PLEASE_CONTACT" | text }}
            <a href="mailto:support@bannerflow.com"
                >{{"NotFoundPageComponent.support@bannerflow.com" | text }}</a
            >
        </p>
        <p class="third-ph">{{"NotFoundPageComponent.THE_BANNERFLOW_TEAM" | text }}</p>
    </div>
</div>

<ng-template #loading>
    <ui-loader></ui-loader>
</ng-template>
